<template>
  <v-card>
    <v-toolbar dark dense flat>
      <v-toolbar-title class="white--text">
        {{ vm ? vm.name : '' }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-list dense>
        <v-list-item>
          <v-list-item-content>Machine Name:</v-list-item-content>
          <v-list-item-content class="align-end">
            {{ vm.name }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>Subscription:</v-list-item-content>
          <v-list-item-content class="align-end">
            {{ vm.subscriptionName }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>Resource Group:</v-list-item-content>
          <v-list-item-content class="align-end">
            {{ vm.resourceGroup }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>State:</v-list-item-content>
          <v-list-item-content class="align-end">
            {{ vm.powerState }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>Operation:</v-list-item-content>
          <v-list-item-content class="align-end">
            {{ vm.operation }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions class="pt-0">
      <v-spacer />
      <v-btn
        v-if="canStart"
        :disabled="vm.operationInProgress"
        text
        color="primary"
        @click="onStart"
      >
        Start
      </v-btn>
      <v-btn
        v-if="canRestart"
        :disabled="vm.operationInProgress"
        text
        color="warning"
        @click="onRestart"
      >
        Restart
      </v-btn>
      <v-btn
        v-if="canDeallocate"
        :disabled="vm.operationInProgress"
        text
        color="error"
        @click="onDeallocate"
      >
        Deallocate
      </v-btn>
      <v-btn
        color="primary darken-1"
        text
        @click.native="$emit('close')"
      >
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import VmService from "@/services/vm";

export default {
  props: {
    vm: {
      type: Object,
      required: false
    },
  },
  computed: {
    ...mapGetters(["accessToken"]),
    canStart() {
      return this.vm.canStart && (this.vm.powerState === "Deallocated" || this.vm.powerState === "Stopped");
    },
    canRestart() {
      return this.vm.canRestart && this.vm.powerState === "Running";
    },
    canDeallocate() {
      return this.vm.canDeallocate && this.vm.powerState === "Running";
    },
  },
  data: () => ({
  }),
  watch: { 
    vm: function(newValue) {
      this.updateVmState(newValue);
    }
  },
  mounted() {
    this.updateVmState();
  },
  methods: {
    async updateVmState(vm) {
      if (vm) {
        await VmService.updateVmState(this.accessToken, vm);
      }
      else {
        await VmService.updateVmState(this.accessToken, this.vm)
      }
    },
    async onStart() {
      await VmService.startVm(this.accessToken, this.vm);
    },
    async onRestart() {
      if (
        await this.$root.$confirm(
          `Restart VM ${this.vm.name}`,
          "Are you sure?",
          { color: "warning" }
        )
      ) {
        await VmService.restartVm(this.accessToken, this.vm);
      }
    },
    async onDeallocate() {
      if (
        await this.$root.$confirm(
          `Deallocate VM ${this.vm.name}`,
          "Are you sure?",
          { color: "warning" }
        )
      ) {
        await VmService.deallocateVm(this.accessToken, this.vm);
      }
    },
  },
};
</script>
