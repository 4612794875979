<template>
<v-container fluid>
  <v-card>
    <v-dialog v-model="showEditVm" max-width="1000">
      <Vm :vm="editVm" @close="showEditVm = false"/>
    </v-dialog>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="vms"
        dense
        class="mt-2 row-cursor"
        fixed-header
        hide-default-footer
        :items-per-page="itemsPerPage"
        @click:row="onClickRow"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
 <v-snackbar
      v-model="loading"
      timeout="-1"
    >
      Loading...
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Vm from "./Vm";

export default {
  name: "VmList",

  components: {
    Vm,
  },

  props: {
    loading: {
      type: Boolean,
      required: false
    },
  },
  
  data: () => ({
    headers: [
              { text: 'Machine Name', value: 'name' },
              { text: 'Subscription', value: 'subscriptionName' },
              { text: 'Resource Group', value: 'resourceGroup' },
              { text: 'State', value: 'powerState' },
              { text: 'Operation', value: 'operation' },
            ],
    itemsPerPage: -1,
    editVm: null,
    showEditVm: false,
  }),

  computed: {
    ...mapGetters(["vms"]),
  },

  methods: {
    onClickRow(row) {
      this.editVm = row;
      this.showEditVm = true;
    },
  },
};
</script>

<style lang="css" scoped>
.row-cursor >>> tbody tr :hover {
  cursor: pointer;
}
</style>
