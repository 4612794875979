import { AuthorizationManagementClient } from "@azure/arm-authorization";
import { ComputeManagementClient } from "@azure/arm-compute";
import { SubscriptionClient } from "@azure/arm-subscriptions";
import customTokenCredential from "../CustomTokenProvider";

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function getSubscriptions(tokenCredential) {
  const subscriptionClient = new SubscriptionClient(tokenCredential);
  
  let subscriptions = [];
  
  let subscriptionsResponse = undefined;
  do {
    if (subscriptionsResponse) {
      subscriptionsResponse = await subscriptionClient.subscriptions.listNext(subscriptionsResponse.nextLink);
    } else {
      subscriptionsResponse = await subscriptionClient.subscriptions.list();
    }
    subscriptionsResponse.forEach((subs) => {
      subscriptions.push(
        {
          id: subs.subscriptionId,
          name: subs.displayName
        });
    });
  } while (subscriptionsResponse.nextLink);

  return subscriptions;
}

async function getVmsInSubscription(tokenCredential, subscription) {
  let vms = [];

  try {
    const authorizationManagementClient = new AuthorizationManagementClient(tokenCredential, subscription.id);
    const computeManagementClient = new ComputeManagementClient(tokenCredential, subscription.id);

    let iter = undefined;
    do {
      if (iter) {
        iter = await computeManagementClient.virtualMachines.listAllNext(iter.nextLink);
      } else {
        iter = await computeManagementClient.virtualMachines.listAll();
      }
      for (const element of iter) {
        //console.log(element);
        const regex = /\/resourceGroups\/([^/]*).*/
        let m = element.id.match(regex);
        const rg = m[1];
        const auth = await authorizationManagementClient.permissions.listForResource(
          rg, "Microsoft.Compute", "", "virtualMachines", element.name);
        let canStart = false;
        let canRestart = false;
        let canDeallocate = false;
        for (const a of auth) {
          a.actions.forEach(act => {
            if (act === "*" || act === "Microsoft.Compute/virtualMachines/*" || act === "Microsoft.Compute/virtualMachines/start/action") {
              canStart = true;
            }
            if (act === "*" || act === "Microsoft.Compute/virtualMachines/*" || act === "Microsoft.Compute/virtualMachines/restart/action") {
              canRestart = true;
            }
            if (act === "*" || act === "Microsoft.Compute/virtualMachines/*" || act === "Microsoft.Compute/virtualMachines/deallocate/action") {
              canDeallocate = true;
            }
          })
        }
        if (canStart || canRestart || canDeallocate) {
          vms.push({
            id: element.id,
            name: element.name,
            subscriptionId: subscription.id,
            subscriptionName: subscription.name,
            resourceGroup: rg,
            powerState: "",
            operation: "",
            operationInProgress: false,
            canStart,
            canRestart,
            canDeallocate
          });
        }
      }
    } while (iter.nextLink);
  } catch (err) {
    console.log("Could not list VMs for subscription " + subscription.name + ": " + err.message);
  }
  return vms;
}

async function updateAllVmStates(accessToken, vms) {
  let tasks = [];
  for (let vm of vms) {
    const vmTask = updateVmState(accessToken, vm);
    tasks.push(vmTask);
  }
  await Promise.all(tasks);
}

async function updateVmState(accessToken, vm) {
  try {
    const tokenCredential = new customTokenCredential(accessToken);
    const computeManagementClient = new ComputeManagementClient(tokenCredential, vm.subscriptionId);
    const iv = await computeManagementClient.virtualMachines.instanceView(vm.resourceGroup, vm.name);
    //console.log(iv);
    iv.statuses.forEach(s => {
      if (s.code.startsWith("PowerState/")) {
          let ps = s.code.split("/")[1];
          vm.powerState = ps[0].toUpperCase() + ps.substring(1);
      }
    });
  } catch (err) {
    console.log("Could not refresh VM state for " + vm.name);
  }
}

async function startVm(accessToken, vm) {
    const tokenCredential = new customTokenCredential(accessToken);
    const computeManagementClient = new ComputeManagementClient(tokenCredential, vm.subscriptionId);

    vm.operation = "Start VM in progress"
    vm.operationInProgress = true;
    const operation = await computeManagementClient.virtualMachines.beginStart(vm.resourceGroup, vm.name);
    let pollResult = '';
    while (!operation.isFinished()) {
      await sleep(2000);
      await updateVmState(accessToken, vm);
      pollResult = await operation.poll();
      //console.log(pollResult);
    }
    vm.operation = "Start VM " + pollResult[0].toLowerCase() + pollResult.substring(1);
    vm.operationInProgress = false;
}

async function restartVm(accessToken, vm) {
    const tokenCredential = new customTokenCredential(accessToken);
    const computeManagementClient = new ComputeManagementClient(tokenCredential, vm.subscriptionId);

    vm.operation = "Restart VM in progress"
    vm.operationInProgress = true;
    const operation = await computeManagementClient.virtualMachines.beginRestart(vm.resourceGroup, vm.name);
    let pollResult = '';
    while (!operation.isFinished()) {
      await sleep(2000);
      await updateVmState(accessToken, vm);
      pollResult = await operation.poll();
      //console.log(pollResult);
    }
    vm.operation = "Restart VM " + pollResult[0].toLowerCase() + pollResult.substring(1);
    vm.operationInProgress = false;;
}

async function stopVm(accessToken, vm) {
    const tokenCredential = new customTokenCredential(accessToken);
    const computeManagementClient = new ComputeManagementClient(tokenCredential, vm.subscriptionId);

    vm.operation = "Stop VM in progress"
    vm.operationInProgress = true;
    const operation = await computeManagementClient.virtualMachines.beginPowerOff(vm.resourceGroup, vm.name);
    let pollResult = '';
    while (!operation.isFinished()) {
      await sleep(2000);
      await updateVmState(accessToken, vm);
      pollResult = await operation.poll();
      //console.log(pollResult);
    }
    vm.operation = "Stop VM " + pollResult[0].toLowerCase() + pollResult.substring(1);
    vm.operationInProgress = false;
}

async function deallocateVm(accessToken, vm) {
    const tokenCredential = new customTokenCredential(accessToken);
    const computeManagementClient = new ComputeManagementClient(tokenCredential, vm.subscriptionId);

    vm.operation = "Deallocate VM in progress"
    vm.operationInProgress = true;
    const operation = await computeManagementClient.virtualMachines.beginDeallocate(vm.resourceGroup, vm.name);
    let pollResult = '';
    while (!operation.isFinished()) {
      await sleep(2000);
      await updateVmState(accessToken, vm);
      pollResult = await operation.poll();
      //console.log(pollResult);
    }
    vm.operation = "Deallocate VM " + pollResult[0].toLowerCase() + pollResult.substring(1);
    vm.operationInProgress = false;
}

export default {
  getSubscriptions,
  getVmsInSubscription,
  updateVmState,
  updateAllVmStates,
  startVm,
  restartVm,
  stopVm,
  deallocateVm,
}
