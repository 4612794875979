<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-4">
        <div v-if="messageLine1 !== null">
          {{ messageLine1 }}
        </div>
        <div v-if="messageLine2 !== null">
          {{ messageLine2 }}
        </div>
        <div v-if="messageLine3 !== null">
          {{ messageLine3 }}
        </div>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn color="primary darken-1" text @click.native="agree">
          {{ options.confirmTitle }}
        </v-btn>
        <v-btn color="grey" text @click.native="cancel">
          {{ options.cancelTitle }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 400,
      zIndex: 200,
      confirmTitle: 'Yes',
      cancelTitle: 'Cancel'
    }
  }),
  computed: {
    messageLine1() {
      if (Array.isArray(this.message)) {
        if (this.message.length >= 1) {
          return this.message[0];
        } else {
          return null;
        }
      } else {
        return this.message;
      }
    },
    messageLine2() {
      if (Array.isArray(this.message) && this.message.length >= 2) {
        return this.message[1];
      } else {
        return null;
      }
    },
    messageLine3() {
      if (Array.isArray(this.message) && this.message.length >= 3) {
        return this.message[2];
      } else {
        return null;
      }
    }
  },
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>
