import Vue from "vue";
import Vuex from "vuex";
import customTokenCredential from "../CustomTokenProvider";
import VmService from "@/services/vm";

Vue.use(Vuex);

async function startAutoUpdateVmStates( state ) {
  if (state.updateVmTimer) {
    clearTimeout(state.updateVmTimer);
    state.updateVmTimer = undefined;
  }
  console.log("Refreshing VM states");
  await VmService.updateAllVmStates(state.accessToken, state.vms);
  // Update every 15 minutes
  state.updateVmTimer = setTimeout(() => { startAutoUpdateVmStates(state) }, 15 * 60 * 1000);
}

export default new Vuex.Store({
  state: {
    msalConfig: {
      auth: {
        clientId: process.env.NODE_ENV === "development" ? "9405a6d3-0b32-4e59-805b-156bb7d17aac" : "4ba02ac3-cd9f-4a3d-b1be-477348c7b2dc",
        authority: "https://login.microsoftonline.com/bf7cb870-b378-42ab-a618-31704bc2e9b7",
      },
      cache: {
        cacheLocation: "localStorage",
      },
    },
    accessToken: "",
    vms: [],
    updateVmTimer: undefined
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    clearVms(state) {
      state.vms.length = 0;
    },
    appendVms(state, vms) {
      state.vms.push(...vms);
      //console.log("VMS: " + state.vms.length)
    },
  },
  getters: {
    accessToken: (state) => state.accessToken,

    vms: (state) => state.vms,
  },
  actions: {
    async populateVmList({ state, commit }) {
      commit("clearVms");

      const tokenCredential = new customTokenCredential(state.accessToken);
      var subscriptions = await VmService.getSubscriptions(tokenCredential);
      let tasks = [];
      // Enumerate all subscriptions in parallell
      for (let subscription of subscriptions) {
        const vmTask = VmService.getVmsInSubscription(tokenCredential, subscription).
          then((vmsInSubscription) => {
            console.log("Subscription '" + subscription.name + "': " + vmsInSubscription.length + " virtual machines");
            commit('appendVms', vmsInSubscription);
          });
        tasks.push(vmTask);
      }
      await Promise.all(tasks);
      console.log("Done, " + state.vms.length + " virtual machines discovered.");

      // Update state of all VMs in the background
      startAutoUpdateVmStates(state);
    },
  },
  modules: {},
});
