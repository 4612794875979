import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Emitter from 'tiny-emitter';
import AsyncComputed from 'vue-async-computed'
import VueTimers from 'vue-timers'

Vue.config.productionTip = false;

vuetify.framework.theme.dark = true;

Vue.use(AsyncComputed);
Vue.use(VueTimers);

const vue = new Vue({
  store,
  vuetify,
  msalInstance: {},
  emitter: new Emitter(),
  render: (h) => h(App),
})

vue.$mount("#app");
