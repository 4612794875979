<template>
  <v-app>
    <v-app-bar app color="secondary" dark>
      <KongsbergLogo size="40" />
      <h2 class="ml-5">Virtual machines</h2>

      <v-spacer></v-spacer>

      <span
        v-if="account"
        class="mr-5"
        >
        {{ account.username }} 
      </span>

      <v-btn
        v-if="account"
        @click="onLogout"
        text
      >
        <span class="mr-2">Logout</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
      <v-btn
        v-if="!account"
        @click="onLogin"
        text
      >
        <span class="mr-2">Login</span>
        <v-icon>mdi-login</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <Confirm ref="confirm" />
      <VmList v-if="account" :loading="loading" />
    </v-main>
  </v-app>
</template>

<script>
import VmList from "./components/VmList";
import KongsbergLogo from "./components/KongsbergLogo";
import Confirm from "./components/Confirm";
import { PublicClientApplication } from '@azure/msal-browser';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: "App",

  components: {
    Confirm,
    KongsbergLogo,
    VmList,
  },

  data: () => ({
    account: undefined,
    loggedIn: false,
    loading: false,
  }),

  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );
  },

  mounted() {
    this.$root.$confirm = this.$refs.confirm.open;
  },

  methods: {
    ...mapMutations(['setAccessToken']),
    ...mapActions(['populateVmList']),
    async onLogin() {
      let request = {
        scopes: ['https://management.azure.com/user_impersonation'],
      };
      try {
        const tokenResponse = await this.$msalInstance.acquireTokenSilent(request);
        this.setAccessToken(tokenResponse.accessToken);
      } catch (error) {
        //console.error('Silent token acquisition failed. Using interactive mode');
        try {
          const tokenResponse = await this.$msalInstance.acquireTokenPopup(request);
          //console.log(`Access token acquired via interactive auth ${tokenResponse.accessToken}`)
          this.setAccessToken(tokenResponse.accessToken);
        } catch (error) {
          console.error('Interactive token acquisition failed.');
          return;
        }
      }
      const myAccounts = this.$msalInstance.getAllAccounts();
      this.account = myAccounts[0];
      //this.$emitter.emit('login', this.account);
      this.loading = true;
      await this.populateVmList();
      this.loading = false;
    },

    async onLogout() {
      await this.$msalInstance
        .logout({})
        .then(() => {
          //this.$emitter.emit('logout', 'logging out');
          this.account = undefined;
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>
